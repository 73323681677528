var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"pt-4 pt-md-11"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-7 col-lg-6 order-md-1"},[_vm._m(1),_vm._v(" "),_c('p',{staticClass:"lead text-center text-md-start text-muted mb-2"},[_vm._v("\n                        We provide fault tolerant\n                        "),_c('a',{attrs:{"href":`${_vm.$root.siteProto}://api.${_vm.$root.siteDomain}/docs`}},[_vm._v("RESTful API")]),_vm._v("\n                        for analytics and SMM. You can automate your posts,\n                        followers, hashtags, GEO and much more else\n                    ")]),_vm._v(" "),_c('p',{staticClass:"lead text-center text-md-start text-muted mb-2"}),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$root.context.id),expression:"!$root.context.id"}],staticClass:"lead text-center text-md-start text-muted mb-2 mb-lg-8"},[_vm._v("\n                        We guarantee that your requests will work quickly\n                        and the correct answer will be returned, without\n                        blocking from the social network\n                    ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.$root.context.promocode &&
                            _vm.$root.context.free_requests
                        ),expression:"\n                            $root.context.promocode &&\n                            $root.context.free_requests\n                        "}],staticClass:"lead text-center text-md-start text-muted mb-2 mb-lg-8"},[_vm._v("\n                        "+_vm._s(_vm.$root.context.free_requests)+" free requests for\n                        new clients!\n                    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$root.context.id),expression:"!$root.context.id"}],staticClass:"text-center text-md-start"},[_c('router-link',{staticClass:"btn btn-primary shadow lift me-1",attrs:{"to":{ path: '/login' }}},[_vm._v("\n                            Login\n                            "),_c('i',{staticClass:"fe fe-arrow-right d-none d-md-inline ms-3"})]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary-soft lift",attrs:{"to":{ path: '/registration' }}},[_vm._v("\n                            Registration\n                        ")])],1)])])])]),_vm._v(" "),_c('section',{staticClass:"py-1 py-md-2"},[_c('Plans')],1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('section',{staticClass:"py-2 py-md-6"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-10 col-lg-8 text-center"},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$root.context.id),expression:"!$root.context.id"}]},[_c('router-link',{staticClass:"fw-bold text-primary-desat",attrs:{"to":{ path: '/registration' }}},[_vm._v("Register")]),_vm._v("\n                        and start using our service!\n                    ")],1),_vm._v(" "),_c('p',{staticClass:"fs-lg text-muted mb-7 mb-md-9"},[_vm._v("\n                        Fund up your account in your personal dashboard, get\n                        token and use any plan\n                    ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-5 col-lg-6 order-md-2"},[_c('img',{staticClass:"img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0",attrs:{"src":"/assets/img/illustrations/illustration-6.png","alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"display-4 text-center text-md-start"},[_c('span',{staticClass:"text-primary text-nowrap"},[_vm._v("\n                            Private API for TT ")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pt-6 pb-8 pt-md-10"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-md-5 col-lg-6 order-md-2"},[_c('img',{staticClass:"img-fluid mb-6 mb-md-0",attrs:{"src":"assets/img/illustrations/illustration-3.png","alt":"..."}})]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-7 col-lg-6 order-md-1"},[_c('h2',[_vm._v("\n                        Stay focused on your business"),_c('br'),_vm._v(" "),_c('span',{staticClass:"text-primary"},[_vm._v("Trust us to make API requests")])]),_vm._v(" "),_c('p',{staticClass:"fs-lg text-gray-700 mb-2"},[_vm._v("\n                        You no longer need to handle errors, we\n                        independently make requests and automatically solve\n                        problems and provide you with unhindered access to\n                        public data\n                    ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('h3',{staticClass:"mb-0"},[_c('span',[_vm._v("91")]),_vm._v("%")]),_vm._v(" "),_c('p',{staticClass:"text-gray-700 fs-3 mb-0"},[_vm._v("Uptime")])])])])])])
}]

export { render, staticRenderFns }