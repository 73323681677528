<template>
    <div>
        <section class="pt-4 pt-md-11">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-5 col-lg-6 order-md-2">
                        <img
                            src="/assets/img/illustrations/illustration-6.png"
                            class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0"
                            alt="..."
                        />
                    </div>
                    <div class="col-12 col-md-7 col-lg-6 order-md-1">
                        <h1 class="display-4 text-center text-md-start">
                            <span class="text-primary text-nowrap">
                                Private API for TT </span
                            ><br />
                        </h1>
                        <p
                            class="lead text-center text-md-start text-muted mb-2"
                        >
                            We provide fault tolerant
                            <a
                                :href="`${$root.siteProto}://api.${$root.siteDomain}/docs`"
                                >RESTful API</a
                            >
                            for analytics and SMM. You can automate your posts,
                            followers, hashtags, GEO and much more else
                        </p>
                        <p
                            class="lead text-center text-md-start text-muted mb-2"
                        >
                            <!-- We use the Web and Mobile API and provide our clients a pool of proxies, a collection of devices and our own accounts to trouble-free receive public data -->
                        </p>

                        <p
                            class="lead text-center text-md-start text-muted mb-2 mb-lg-8"
                            v-show="!$root.context.id"
                        >
                            We guarantee that your requests will work quickly
                            and the correct answer will be returned, without
                            blocking from the social network
                        </p>
                        <p
                            class="lead text-center text-md-start text-muted mb-2 mb-lg-8"
                            v-show="
                                $root.context.promocode &&
                                $root.context.free_requests
                            "
                        >
                            {{ $root.context.free_requests }} free requests for
                            new clients!
                        </p>
                        <div
                            class="text-center text-md-start"
                            v-show="!$root.context.id"
                        >
                            <router-link
                                :to="{ path: '/login' }"
                                class="btn btn-primary shadow lift me-1"
                            >
                                Login
                                <i
                                    class="fe fe-arrow-right d-none d-md-inline ms-3"
                                ></i>
                            </router-link>
                            <router-link
                                :to="{ path: '/registration' }"
                                class="btn btn-primary-soft lift"
                            >
                                Registration
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="pt-8 pb-4 pt-md-10 pb-md-6">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-10 text-center">
                        <h2>
                            We provide a
                            <span class="fw-bold text-primary-desat"
                                >proxy servers and accounts for free</span
                            >
                        </h2>
                        <p>
                            We provide thousands of proxy servers for all plans,
                            a collection of devices and tens of thousands of
                            accounts for requests on social networks
                        </p>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="py-1 py-md-2">
            <Plans />
        </section>
        <section class="pt-6 pb-8 pt-md-10">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-5 col-lg-6 order-md-2">
                        <img
                            src="assets/img/illustrations/illustration-3.png"
                            alt="..."
                            class="img-fluid mb-6 mb-md-0"
                        />
                    </div>
                    <div class="col-12 col-md-7 col-lg-6 order-md-1">
                        <h2>
                            Stay focused on your business<br />
                            <span class="text-primary"
                                >Trust us to make API requests</span
                            >
                        </h2>
                        <p class="fs-lg text-gray-700 mb-2">
                            You no longer need to handle errors, we
                            independently make requests and automatically solve
                            problems and provide you with unhindered access to
                            public data
                        </p>
                        <!-- <div class="d-flex"> -->
                        <div class="d-flex align-items-center gap-2">
                            <h3 class="mb-0"><span>91</span>%</h3>
                            <p class="text-gray-700 fs-3 mb-0">Uptime</p>
                        </div>
                        <!-- <div class="border-start border-gray-300"></div> -->
                        <!-- <div class="ps-6">
                                <h3 class="mb-0">
                                    <span>1984</span>
                                </h3>
                                <p class="text-gray-700 mb-0">Proxies</p>
                            </div> -->
                        <!-- <div class="border-start border-gray-300"></div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="py-2 py-md-6">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 text-center">
                        <h2 v-show="!$root.context.id">
                            <router-link
                                class="fw-bold text-primary-desat"
                                :to="{ path: '/registration' }"
                                >Register</router-link
                            >
                            and start using our service!
                        </h2>
                        <p class="fs-lg text-muted mb-7 mb-md-9">
                            Fund up your account in your personal dashboard, get
                            token and use any plan
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Plans from "./Plans";
export default {
    components: { Plans },
    data() {
        return {};
    },
    created() {
        if (this.$route.params && this.$route.params.promocode)
            localStorage.setItem("promocode", this.$route.params.promocode);
        if (location.search.indexOf("paymentId") != -1) {
            this.$router.replace({ path: "/billing" });
        }
    },
};
</script>
